@import "./theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif;
  scroll-behavior: smooth;
}

.errors-text {
  color: red !important;
}

.empty-seat {
  height: 36px;
  width: 36px;
}

.custom-btn {
  width: 100px;
  height: 45px;
  background-color: $primary-text;
  position: relative;
  border: 1px solid #9d9caf;
  border-radius: 4px;

  img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 5px;
  }
}

.checkmark-container {
  position: absolute;
  top: 0;
  right: 0;
}

.btn-container {
  position: relative;
}

.radio-groups {
  display: flex;
  gap: 10px;
}

.page-container {
  min-height: 80vh;
  margin-top: 68px;
}

.my-modal {
  .modal-content {
    border-radius: 0px;
  }
}

.secondary-modal {
  .modal-content {
    width: initial;
    border-radius: 0px;
  }
}

.error-text {
  color: red;
  font-size: 12px;
}

.date-picker {
  height: 35px;
}

.date-picker-parent {
  position: relative;
  ._2xcMq {
    width: 310px;
  }
}

.date-picker-icon {
  position: absolute;
  right: 10px;
  top: 5px;
}

.wrapper {
  padding: 50px 120px;
  margin: 0 auto;
}

.filled-btn {
  width: 100%;
  height: 40px;
  background-color: $primary-light;
  border: none;
  color: $primary-text;
  border-radius: 4px;
  // padding: 5px 0px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
}

.outline-btn {
  @extend .filled-btn;
  box-shadow: 0px 8px 20px rgba(18, 17, 39, 0.1);
  background-color: transparent;
  border: 2px solid $primary-light;
  color: $primary;
}

.btn-label {
  text-decoration: none;
  text-align: center;
  color: $primary-light;
  font-weight: 600;
  cursor: pointer;
}

// select btn
.select-btn {
  width: 150px;
  height: 50px;
  outline: none;
  border: 1px solid gray;
  border-radius: 8px;
  background: $primary-text;
}

.select-image {
  position: relative;
  height: 100%;
  object-fit: cover;
}

.payment-btn-group {
  display: flex;
  gap: 10px;
}

// passenger details
.detail-value {
  color: $primary;
  font-weight: 600;
  font-size: 14px;
}

.my-navbar {
  border-top: 8px solid $primary-dark;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0px 0px 40px 0px #efecec;
  background-color: #fff;
  .navbar-wrapper {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      column-gap: 12px;
      .company-name {
        font-size: 20px;
        font-weight: 600;
        color: #298b5b;
      }
    }
    img {
      height: 40px;
      width: auto;
      object-fit: fill;
    }

    .nav-buttons {
      display: flex;
      align-items: center;
      .register-btn {
        padding: 5px 15px;
        border-radius: 4px;
        font-size: 15px;
        height: 36px;
        background: $primary-light;
        border: none;
        color: $primary-text;
        transition: all 0.1s ease-in;
        &:hover {
          background: $primary-dark;
        }
      }
      .login-btn {
        padding: 5px 15px;
        border-radius: 4px;
        background: #fff;
        font-size: 15px;
        height: 36px;
        margin-left: 15px;
        transition: all 0.1s ease-in;
        border: none;
        &:hover {
          background: $background;
        }
      }
      .profile-wrapper {
        .drop-down-item {
          font-size: 14px;
          font-weight: 700;
          padding-left: 5px;
        }
        .profile-box {
          display: flex;
          align-items: center;
          .profile-text {
            font-family: "Red Hat Display", sans-serif;
            color: #047857;
            font-size: 16px;
          }
        }
        button {
          background: none;
          border: none;
          display: flex;
          align-items: center;
          &:focus {
            box-shadow: none;
          }
        }
        .dropdown-toggle::after {
          color: $primary;
        }
      }
    }
  }
}
// home page
.banner-section {
  position: relative;
  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000004d;
    position: absolute;
    content: "";
  }
  img {
    height: 580px;
    object-fit: cover;
    width: 100%;
  }
  .banner-search {
    position: absolute;
    z-index: 99;
    bottom: -45px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1140px;
  }
}
.arrowIconLeft {
  left: 5%;
  display: "block";
  background: #fff;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  top: 50%;
  bottom: 50%;
  cursor: pointer;
}
.arrowIconRight {
  right: 5%;
  display: "block";
  background: #fff;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  top: 50%;
  bottom: 50%;
  cursor: pointer;
}
.assoArrowIconLeft {
  left: -5%;
  display: "block";
  background: #fff;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  top: 50%;
  bottom: 50%;
  cursor: pointer;
}
.assoArrowIconRight {
  right: -5%;
  display: "block";
  background: #fff;
  color: red;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  top: 50%;
  bottom: 50%;
  cursor: pointer;
}

.heroImgContainer {
  // height: 100%;
  width: 100%;
  position: relative;
  background: #201f37;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  .heroTextWrap {
    color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex !important;
    align-items: center;
    top: 0;
    left: 0;
    text-align: left;
    background: rgba(55, 55, 72, 0.65);
    flex-direction: column;
    .heroText {
      // color: #fff !important;
      color: #fff;
      width: 90%;
      text-align: center;
      font-size: 70px !important;
      display: flex;
      line-height: normal;
      font-weight: 800 !important;
    }
  }
  .heroImg {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    position: top;
  }
}

.section-title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  color: $text;
}

// home page bus section
.section-wrapper {
  padding: 100px 0px;
  background-color: #f5f5f5;

  .section-grid {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    // margin: 0 auto;
    padding-top: 40px;
    justify-content: center;
  }
}

.slider-section-wrapper {
  padding-top: 80px;
  padding-bottom: 100px;
  background-color: #f9f9fb;
}

.teamCardWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  cursor: pointer;
}

.grid-container {
  width: 100%;
  // margin: 0 auto;
  display: flex;
  justify-content: center;
}

// home page / card

.card-wrapper {
  width: 100%;
  width: 320px;
  height: 305px;
  overflow: hidden;
  background-color: white;
  padding: 2px;
  padding-bottom: 10px;
  cursor: pointer;
  // box-shadow: 0px 2px 45px 10px #80808030;

  .card-title {
    font-size: 16px;
    font-weight: bold;
    color: $text;
    padding: 2px 0px;
    min-height: 45px;
  }

  .card-subtitle {
    font-size: 12px;
    font-weight: lighter;
    padding-bottom: 10px;
  }

  .image-container {
    max-width: 100%;
    // min-height: 190px;
    // max-height: 190px;
    height: 190px;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
  }

  .card-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
  }

  .slider-image-container {
    max-width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
  }
  .slider-card-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
  }

  .content-container {
    padding: 15px 10px 2px 15px;
  }

  .card-description {
    font-size: 14px;
    font-weight: 500;
    min-height: 30px;
    color: $text;
  }
}

.card-wrapper-slider {
  max-width: 320px;
  min-height: 235px;
  overflow: hidden;
  background-color: white;
  padding: 2px;
  padding-bottom: 10px;
  cursor: pointer;
  // box-shadow: 0px 2px 45px 10px #80808030;

  .card-title {
    font-size: 16px;
    font-weight: bold;
    color: $text;
    padding: 2px 0px;
    min-height: 45px;
  }

  .card-subtitle {
    font-size: 12px;
    font-weight: lighter;
    padding-bottom: 10px;
  }

  .image-container {
    max-width: 100%;
    // min-height: 190px;
    // max-height: 190px;
    height: 190px;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
  }

  .card-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
  }

  .slider-image-container {
    max-width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
  }
  .slider-card-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
  }

  .content-container {
    padding: 15px 10px 2px 15px;
  }

  .card-description {
    font-size: 14px;
    font-weight: 500;
    min-height: 30px;
    color: $text;
    margin-bottom: -16px;
  }
}

// home - our popular destination
.destination-wrapper {
  width: 100%;
  height: 600px;
  background-color: $primary-light;
  overflow: hidden;
  position: relative;
  padding: 60px 0;
  z-index: 0;

  .wrapper-sticker {
    max-width: 150px;
    max-height: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $primary-dark;
    text-align: center;
    padding: 14px;

    p {
      color: $primary-text;
      font-size: 12px;
      font-weight: 500;
    }

    img {
      height: 25px;
      margin-top: -10px;
    }
  }

  .destination-background {
    background-image: url("../assets/images/temples.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .destination-title {
    font-size: 32px;
    text-align: center;
    color: $primary-text;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .popular-wrapper {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    // grid-gap: 10px;
    padding-top: 20px;
    height: 450px;
    overflow-y: auto;

    p {
      font-size: 16px;
      font-weight: 500;
      color: $primary-text;
      line-height: 25px;
      margin-bottom: 0;
    }
  }

  .popular-destination-title {
    font-size: 13px;
    font-weight: 500;
    color: $primary-text;
  }

  .image-container {
    min-width: 560px;
    height: 100%;
  }

  .destination-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
  }
}

// our associations
.association-wrapper {
  background: #f5f5f5;
  padding: 50px 0px;
}

.imp {
  width: "10px !important";
}

.association-image {
  height: 45%;
  // width: 100%;
  width: 15%;
  aspect-ratio: 3/2;
  object-fit: contain;

  // padding: 15px 50px;
}

.footer-wrapper {
  width: 100%;
  color: $primary-text;
  background-color: $dark-background;
  padding: 20px 0px;

  .footer-logo {
    height: 30px;
    width: auto;
  }

  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 100px;
  }

  .footer-title {
    font-size: 14px;
    text-transform: uppercase;
    color: $background;
  }
  .payment-icon-box {
    display: flex;
    justify-content: flex-start;
    // height: 35px;
    // width: 35px;
    // object-fit: cover;
  }

  .payment-icon {
    // width: 100%;
    // height: 100%;
    padding: 2px;
    height: 100%;
    // width: 100%;
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }

  .slogan-container {
    width: 30%;

    .footer-slogan {
      color: $background;
      padding-top: 10px;
      font-size: 14px;
    }
  }

  .copyright-container {
    padding: 0px 100px;
    h4 {
      color: #9d9caf;
      font-size: 14px;
    }
  }

  .support-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .link {
    text-decoration: none;
  }

  .footer-link {
    color: white;
    font-size: 14px;
  }
}

.confirmation-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
}

.confirmation-wrapper {
  padding: 50px 0px;
}

.form-label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  color: #4c4b63;
}

.form-labels {
  @extend .form-label;
  margin-top: 10px;
}

.required-label {
  color: $error;
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px;
}

.input-display {
  width: 100%;
  display: block;
  padding-top: 15px;

  .form-control {
    background-color: $input-background;
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
}

.form-container {
  padding-left: 40px;
}

// form wrapper

.form-wrapper {
  width: 550px;
  border: 2px solid $primary-light;
  border-radius: 8px;
  overflow: hidden;
  // margin-top: 50px;

  .form-input-container {
    padding: 10px 20px;
  }

  .inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .btn-groups {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
  }

  .title-container {
    width: 100%;
    background: $background;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 10px 20px;

    h3 {
      font-weight: bold;
      font-size: 18px;
      color: $text;
    }

    img {
      height: 24px;
    }
  }
}

// summary wraapper
.summary-wrapper {
  width: 400px;
  background: $primary-text;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
  padding-bottom: 40px;

  .summary-container {
    padding: 0px 20px;

    .btn-container {
      padding-top: 20px;
    }
  }

  .hr {
    border-bottom: 1.5px solid $primary-light;
  }

  p {
    font-size: 16px;
    color: white;
    background-color: $primary;
    padding: 10px 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
  }

  .travel-title {
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    padding-bottom: 10px;
  }

  .travel-details-container {
    border-bottom: 1.8px solid $primary-light;
    margin-bottom: 20px;

    .details-container {
      display: grid;
      grid-template-columns: 0.5fr 2fr;
      // grid-column-gap: -20px;

      span {
        font-size: 13px;
        font-weight: 600;
        color: $primary-green-text;
        padding: 10px;
        min-width: 85px;
      }
    }
  }

  .payment-details-container {
    // @extend .travel-details-container;
    border-bottom: 1.8px solid $primary-light;
    padding-bottom: 10px;

    .details-container {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 13px;
        color: $primary-green-text;
        font-weight: 800;
        padding: 5px;
      }
      span {
        font-size: 17px;
        color: $primary-green-text;
        font-weight: 800;
      }
    }
  }
}

// ticket info page
.ticket-container {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 100px;

  .showcase-container {
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 4px;
  }

  .show-case-title {
    font-size: 18px;
    color: $secondary-green-text;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .destination-container {
    display: flex;
    flex-direction: column;
    gap: -40px;
    p {
      font-size: 11px;
      color: $dark-background;
      text-transform: uppercase;
      font-weight: 600;
    }

    h4 {
      font-size: 12px;
      color: $dark-background;
      font-weight: 700;
      margin-top: -12px;
      margin-bottom: 10px;
    }
  }
  .down-btn {
    background: $primary-light;
    width: 100%;
    height: 45px;
    outline: none;
    text-align: center;
    padding: 5px 0px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: $primary-text;
    letter-spacing: 1px;

    .down-icon {
      margin-right: 10px;
    }
  }
}

.ticket-wrapper {
  width: 350px;
  padding: 30px;
  .ticketdata-container {
    width: 100%;
    display: flex;
    align-items: flex-start;

    h4 {
      width: 30%;
      font-size: 11px;
      color: $dark-background;
      text-transform: uppercase;
      font-weight: 500;
    }

    p {
      width: 70%;
      font-size: 13px;
      font-weight: 600;
      color: $text;
      margin-top: -4px;
    }

    .ticket-data-bold {
      font-size: 13px;
      font-weight: 700;
      color: $dark-background;
    }
  }
}

.ticket-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ticket-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    .ticket-success-img-box {
      height: 200px;
      .ticket-success-img {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      color: $primary-light;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      padding-top: 10px;
    }
    button {
      border: 1px solid rgba(18, 17, 39, 0.4);
      background-color: $primary-text;
      color: rgba(18, 17, 39, 0.4);
      border-radius: 4px;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      padding: 5px;
      margin-top: 10px;

      &:hover {
        background: $primary;
        color: $primary-text;
      }
    }
  }
}

.search-section {
  background: #fff;
  box-shadow: 0px 2px 45px 10px #80808030;
  border-radius: 5px;
  form {
    display: flex;
    .item-wrap {
      padding: 15px 10px 10px 20px;
      width: 18%;
      .label {
        font-size: 11px;
        color: #2c2c2c;
        padding-left: 10px;
        text-transform: uppercase;
      }
      select {
        border: none;
        box-shadow: none;
      }
      input {
        border: none;
        box-shadow: none;
      }
    }

    .item-wrap:nth-child(4) {
      width: 28%;
    }
    button {
      margin-left: 30px;
      flex: 1;
      border: none;
      background: $primary;
      color: $primary-text;
      font-size: 20px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: all 0.1s ease-in;
      &:hover {
        background: $primary-dark;
      }
    }
  }
}

.profile-container-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 120px;
  margin: 0 auto;
  background-color: $background;

  h1 {
    font-size: 22px;
    color: $primary-dark;
    font-weight: 700;
  }
}

.profile-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .profile-form-wrapper {
    padding: 0px 40px;
  }

  .profile-form-container {
    padding: 0px 100px 0px 0px;
    .profile-avatar {
      max-width: 150px;
      max-height: 150px;
      position: relative;
    }
  }

  .password-form-container {
    padding: 0px 0px 0px 100px;
  }

  .btn-wrapper {
    margin-top: 20px;
  }

  .profile-section-title {
    border-bottom: 1px solid $background;

    h3 {
      font-size: 16px;
      color: $primary;
      height: 100%;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .profile-icons {
      margin-right: 5px;
    }
  }
}

// history page
.date-container {
  width: 400px;
  display: flex;
  align-items: center;
  gap: 15px;

  .history-filter-btn {
    padding: 5px 10px;
    background-color: #10b981;
    border: none;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: end;
    margin-top: 23px;
  }
  .picker-container {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: #4c4b63;
    }
    input {
      width: 240px;
      height: 35px;
      background-color: $input-background;
      font-size: 13px;
      font-weight: 500;
      color: $input-text;
      padding-top: 2px;
      padding-left: 5px;
      border-radius: 4px;
      border: 1px solid gray;

      &:hover {
        cursor: pointer;
      }
    }

    .calender {
      width: 250px;
    }
  }
}
.association-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.history-table {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  table {
    width: 850px;
    height: fit-content;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;

    thead {
      background-color: $primary-dark;
      height: 40px;

      th {
        padding: 10px;
        color: $primary-text;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        border-right: 1px solid $background;
      }

      th:first-child {
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }
    }

    tbody {
      background-color: $table-row-color;

      tr {
        height: 60px;
        cursor: pointer;

        td {
          padding: 10px;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          border-top: 1px solid $background;
          color: $text;
        }

        td:first-child {
          border-bottom-left-radius: 4px;
        }

        td:last-child {
          border-bottom-right-radius: 4px;
        }

        .table-data-bold {
          font-weight: 600;
        }

        .table-data-link {
          color: $secondary-green-text;
          font-weight: 600;
          text-decoration: 1px underline $secondary-green-text;
        }

        .table-data-bolder {
          @extend .table-data-bold;
          color: $secondary-green-text;
        }
      }
    }
  }
}

.mobileHead {
  display: none;
}
.webHead {
  display: contents;
}

.mobileTable {
  display: none;
}
.webTable {
  display: contents;
}

@media screen and (max-width: 1400px) {
  .banner-section {
    img {
      height: 480px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .destination-wrapper {
    .popular-wrapper {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

@media screen and (max-width: 992px) {
  .destination-wrapper {
    .popular-wrapper {
      // grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (max-width: 768px) {
  .aboutCompContainer {
    .aboutSectionGrid2 {
      .imageGrid {
        .imageBox {
          width: 100% !important;
          height: 250px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
  .wrapper {
    padding: 50px 15px;
    margin: 0 auto;
  }
  .profile-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .profile-form-container {
      padding: 0px 100px 0px 100px;
    }

    .password-form-container {
      padding: 0px 100px 0px 100px;
      margin-top: 5rem;
    }
  }
  .bus-seat-section {
    width: 100% !important;
    padding: 5px !important;
    .bus-seat-index {
      max-width: 100% !important;
      padding-left: 0px !important;
      li {
        padding: 0 5px !important;
        margin-top: 220px !important;
        img {
          height: 30px !important;
        }
      }
    }

    .bus-seat-wrapper {
      min-width: 100% !important;
      margin-top: 200px !important;
      transform: rotate(90deg);
      border-top: 1px solid #d6d6d6 !important;
      // border: none !important;
      // border-right: none !important;
      // border-radius: 4px 0px 0px 4px;
      border-left: 1px solid #d6d6d6 !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: none !important; // border-left: none !important;
      border-radius: 0px !important;
      .bus-seat-area {
        // border-bottom: 1px solid #d6d6d6 !important;
        // border-left: none !important;
        // border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #d6d6d6 !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: none !important; // border-left: none !important;
        .seat-row {
          .seat-holder {
            .seat-no {
              top: -1px !important;
              left: -2px !important;
              transform: rotate(270deg) !important;
            }
          }
        }
      }
    }
  }

  .search-table {
    .booking-wrapper {
      margin: 30px 0 !important;
      max-width: 290px !important;
    }
  }
  .search-table {
    width: 100%;
    .mobileTable {
      .mobileTableContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .mobileTableCard {
          box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.168627451);
          background-color: #ffffff;
          padding: 20px 18px;
          min-width: 400px;
          border-radius: 3px;
          .mobileTableInfoHeadBox {
            .mobileTableHead {
              font-size: 16px;
              color: $text;
              margin-bottom: 10px;
              font-family: "Red Hat Display", sans-serif;
            }
          }
          .mobileTableInfoBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .mobileTableInfoItem {
              display: flex;
              align-items: center;
              color: $input-text;
              .mobileTableInfoText {
                font-size: 14px;
                color: $input-text;
                font-family: "Red Hat Display", sans-serif;

                // color: red !important;
              }
              .mobileTableInfoIcon {
                height: 17px !important;
                width: auto;
                margin-right: 10px;
              }
            }
          }
          .mobileTablePriceBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mobileTablePrice {
              font-size: 14px !important;
              font-weight: bold !important;
              color: #10b981 !important;
              font-family: "Red Hat Display", sans-serif;
            }
            .mobileTablePriceNextBtn {
              background-color: #10b981 !important;
              color: #fff;
              padding: 5px 10px;
              border: none;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .mobileTable {
    display: contents;
  }
  .webTable {
    display: none;
  }
  .mobileHead {
    display: none;
  }
  .webHead {
    display: contents;
  }

  .section-page-wrapper {
    // padding: 100px 0px;
    // background-color: #ffffff;
    .associationImageBox {
      width: 100%;
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .page-container {
    .search-page {
      // width: 100vw;
      margin-top: 320px;
      // overflow-x: scroll;

      .page-title {
        display: flex;
        justify-content: flex-start;
        // text-align: flex-start;
      }
    }
  }

  .search-table {
    table {
      min-width: 800px;
    }
  }
  .page-container {
    .search-banner {
      .search-banner-search {
        bottom: -300px;
      }
    }
  }
  .destination-wrapper {
    .popular-wrapper {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // // grid-template-rows: repeat(2, 1fr);
      // grid-gap: 20px;
      // padding-top: 20px;
      // padding-right: 40px;
    }
    .wrapper-sticker {
      max-width: 150px;
      max-height: 100px;
      // position: absolute;
      display: none;
      bottom: 0;
      right: 0;
      background-color: $primary-dark;
      text-align: center;
      padding: 14px;

      p {
        color: $primary-text;
        font-size: 12px;
        font-weight: 500;
      }

      img {
        height: 25px;
        margin-top: -10px;
      }
    }
    .destination-image {
      display: none;
    }
    .destination-title {
      padding-top: 0px;
      margin-bottom: 0px;
      font-size: 26px;
    }
  }
  .slick-arrow .slick-prev,
  .slick-arrow .slick-next {
    display: none;
  }
  .section-wrapper {
    margin-top: 22rem;
    padding: 10px 10px;
    // background-color: #f5f5f5;

    .section-grid {
      display: flex;
      // grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
      // margin: 0 auto;
      // padding: 40px 20px;
      margin-bottom: 10px;
      // background-color: red;
      flex-direction: column;
      justify-content: center;
    }
  }
  .association-grid-container {
    display: flex;
    justify-content: center;
  }
  .card-wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: white;
    padding: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .arrowIconLeft {
    left: 5%;
    display: "none";
    background: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .arrowIconRight {
    right: 5%;
    display: "none";
    background: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .jsx-wrappper {
    width: "65mm";
  }
  .ticket-wrapper {
    width: 300px;
    padding: 10px;
  }
  .wrapper {
    padding: 50px 10px;
    margin: 0 auto;
  }
  .summary-wrapper {
    width: 400px;
  }
  .form-wrapper {
    width: 400px;
  }
  .banner-section {
    img {
      height: 180px;
    }
    .banner-search {
      top: 50%;
    }
  }
  .search-section {
    margin: 20px;
    form {
      display: block;
      margin-top: 4rem;
      .item-wrap {
        width: 100%;
        padding: 10px 10px 0px 20px;
        font-size: 14px;
      }
      .item-wrap:first-child {
        padding-top: 26px;
      }
      .item-wrap:nth-child(4) {
        width: 100%;
      }

      button {
        margin: 26px 30px;
        width: calc(100% - 60px);
        border-radius: 4px;
        font-size: 16px;
        padding: 5px 30px;
      }
    }
  }
  .ticket-success-img-box {
    padding: 0px 10px;
    height: 100px;
    .ticket-success-img {
      width: 100%;
      height: 100%;
    }
  }
  .my-navbar {
    .navbar-wrapper {
      img {
        height: 20px;
        width: 20px;
      }
      .logo-wrapper {
        column-gap: 10px;
        .company-name {
          font-size: 12px;
          inline-size: 60px;
          overflow-wrap: break-word;
          font-weight: 600;
          color: #298b5b;
        }
      }
    }
  }
  .section-wrapper .section-grid {
    padding-top: 180px;
  }
  .footer-wrapper {
    .copyright-container {
      display: flex;
      justify-content: center;
    }
    .footer-container {
      .footerG {
        margin-bottom: 1rem;
      }
      .footerG2 {
        margin-bottom: 1.5rem;
      }
    }
  }
  .footer-wrapper {
    .footer-container {
      .payment-icon {
        // width: 100%;
        // height: 100%;
        padding: 2px;
        height: 100%;
        // width: 100%;
        width: 40%;
        aspect-ratio: 3/2;
        object-fit: contain;
      }
    }
  }

  .search-section {
    form {
      .item-wrap {
        input {
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  // .search-table {
  //   .bus-seat-section {
  //     .bus-seat-wrapper {
  //       min-width: 550px !important;
  //       margin-top: 200px !important;
  //     }
  //   }
  // }
  .aboutCompContainer {
    .aboutSectionGrid2 {
      .imageGrid {
        .imageBox {
          width: 100% !important;
          height: 200px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
  .bus-seat-section {
    width: 100% !important;
    padding: 5px !important;
    .bus-seat-index {
      max-width: 100% !important;
      padding-left: 0px !important;
      li {
        padding: 0 5px !important;
        margin-top: 220px !important;
        img {
          height: 20px !important;
        }
      }
    }
    .bus-seat-wrapper {
      min-width: 100% !important;
      margin-top: 80px !important;
      margin-bottom: 100px !important;
      transform: rotate(90deg);
      // border-top: 1px solid #d6d6d6 !important;
      // border: none !important;
      // border-right: none !important;
      // border-radius: 4px 0px 0px 4px;
      border-left: 1px solid #d6d6d6 !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: none !important; // border-left: none !important;
      border-radius: 0px !important;

      .bus-seat-area {
        // border-bottom: 1px solid #d6d6d6 !important;
        // border-left: none !important;
        // border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #d6d6d6 !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: none !important; // border-left: none !important;
        .seat-row {
          .seat-holder {
            .seat-no {
              top: -1px !important;
              left: -2px !important;
              transform: rotate(270deg) !important;
            }
          }
        }
      }
    }
  }

  .search-table {
    .booking-wrapper {
      margin: 30px 0 !important;
      max-width: 290px !important;
      ul {
        margin: 30px 10px !important;
        li {
          .title {
            min-width: 70px !important;
          }
        }
      }
    }
  }
  // -----------------------------
  .search-table {
    width: 100%;
    .mobileTable {
      .mobileTableContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .mobileTableCard {
          box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.168627451);
          background-color: #ffffff;
          padding: 20px 18px;
          min-width: 250px;
          border-radius: 3px;
          .mobileTableInfoHeadBox {
            .mobileTableHead {
              font-size: 16px;
              color: $text;
              margin-bottom: 10px;
              font-family: "Red Hat Display", sans-serif;
            }
          }
          .mobileTableInfoBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .mobileTableInfoItem {
              display: flex;
              align-items: center;
              color: $input-text;
              .mobileTableInfoText {
                font-size: 14px;
                color: $input-text;
                font-family: "Red Hat Display", sans-serif;

                // color: red !important;
              }
              .mobileTableInfoIcon {
                height: 17px !important;
                width: auto;
                margin-right: 10px;
              }
            }
          }
          .mobileTablePriceBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mobileTablePrice {
              font-size: 14px !important;
              font-weight: bold !important;
              color: #10b981 !important;
              font-family: "Red Hat Display", sans-serif;
            }
            .mobileTablePriceNextBtn {
              background-color: #10b981 !important;
              color: #fff;
              padding: 5px 10px;
              border: none;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }

  .mobileTable {
    display: contents;
  }
  .webTable {
    display: none;
  }
  .mobileHead {
    display: contents;
  }
  .webHead {
    display: none;
  }
  .section-page-wrapper {
    // padding: 100px 0px;
    // background-color: #ffffff;
    .associationImageBox {
      width: 100%;
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .profile-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
  }
  .search-table {
    table {
      min-width: 800px;
    }
  }
  .login-modal {
    // width: 120px !important;
    .modal-content {
      width: 80%;
      border-radius: 3px !important;
    }
  }
  .login-section {
    padding: 10px;
  }
  .search-page {
    // margin-top: 250px;
    .page-date p {
      font-size: 11px;
    }
    .dateBox {
      justify-content: space-between;
    }
  }
  .date-picker-parent {
    position: relative;
    ._2xcMq {
      width: 310px;
      left: -30px;
    }
  }
  .association-grid-container {
    display: flex;
    justify-content: center;
  }
  .card-wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: white;
    padding: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .section-wrapper {
    margin-top: 22rem;
    padding: 10px 10px;
    // background-color: #f5f5f5;

    .section-grid {
      display: flex;
      // grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
      // margin: 0 auto;
      padding: 20px;
      margin-bottom: 10px;
      // background-color: red;
      flex-direction: column;
      justify-content: center;
    }
  }
  .destination-wrapper {
    .popular-wrapper {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(2, 1fr);
      // grid-gap: 20px;
      // padding-top: 20px;
      // padding-right: 40px;
    }
    .wrapper-sticker {
      max-width: 150px;
      max-height: 100px;
      // position: absolute;
      display: none;
      bottom: 0;
      right: 0;
      background-color: $primary-dark;
      text-align: center;
      padding: 14px;

      p {
        color: $primary-text;
        font-size: 12px;
        font-weight: 500;
      }

      img {
        height: 25px;
        margin-top: -10px;
      }
    }
    .destination-image {
      display: none;
    }
    .destination-title {
      padding-top: 0px;
      margin-bottom: 0px;
    }
  }
  .arrowIconLeft {
    left: 10%;
    display: "hidden";
    background: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .arrowIconRight {
    right: 5%;
    display: "block";
    background: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .footer-wrapper {
    .copyright-container {
      display: flex;
      justify-content: center;
    }
    .footer-container {
      .footerG {
        margin-bottom: 1rem;
      }
      .footerG2 {
        margin-bottom: 1rem;
      }
    }
  }
  .form-wrapper {
    width: 300px;
  }
  .summary-wrapper {
    width: 300px;
  }
  .ticket-wrapper {
    width: 300px;
    padding: 20px;
  }
}

// .modal {
//   .show {
.modal-dialog {
  display: flex;
  justify-content: center;
}
//   }
// }

@media screen and (max-width: 450px) {
  .aboutCompContainer {
    .aboutSectionGrid2 {
      .imageGrid {
        .imageBox {
          width: 100% !important;
          height: 200px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
  .date-picker-parent {
    position: relative;
    ._2xcMq {
      width: 300px;
      left: -30px;
    }
  }
  .date-container {
    .date-picker-parent {
      position: relative;
      ._2xcMq {
        width: 300px;
        left: -73px;
      }
    }
  }
  .history-table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    table {
      overflow-y: scroll;
      width: 850px;
      height: fit-content;
      border-collapse: separate;
      border-spacing: 0;
      margin-top: 20px;
    }
  }

  .date-container {
    width: 100%;
    // display: flex;
    // align-items: center;
    // gap: 15px;
    display: flex;
    flex-direction: column;
    .date-dash {
      display: none;
    }

    .history-filter-btn {
      padding: 5px 58px;
      background-color: #10b981;
      border: none;
      color: #fff;
      border-radius: 4px;
      display: flex;
      justify-content: end;
      margin-top: 10px;
    }
    .picker-container {
      input {
        width: 100%;
        height: 35px;
        background-color: $input-background;
        font-size: 13px;
        font-weight: 500;
        color: $input-text;
        padding-top: 2px;
        padding-left: 5px;
        border-radius: 4px;
        border: 1px solid gray;

        &:hover {
          cursor: pointer;
        }
      }

      .calender {
        width: 250px;
      }
    }
  }

  .wrapper {
    padding: 50px 15px;
    margin: 0 auto;
  }
  .profile-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .profile-form-container {
      padding: 0px 20px 0px 20px;
      .profile-avatar {
        margin: auto !important;
      }
    }

    .password-form-container {
      padding: 0px 20px 0px 20px;
      margin-top: 5rem;
    }
  }
  .bus-seat-section {
    width: 100% !important;
    padding: 5px !important;
    .bus-seat-index {
      max-width: 100% !important;
      padding-left: 0px !important;
      li {
        padding: 0 5px !important;
        margin-top: 220px !important;
        img {
          height: 30px !important;
        }
      }
    }
    .bus-seat-wrapper {
      min-width: 100% !important;
      margin-top: 80px !important;
      margin-bottom: 100px !important;
      transform: rotate(90deg);
      border-left: 1px solid #d6d6d6 !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: none !important;
      // border-right: none !important;
      // border-radius: 4px 0px 0px 4px;
      border-radius: 0px !important;

      .bus-seat-area {
        border-right: 1px solid #d6d6d6 !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: none !important; // border-left: none !important;
        // border-radius: 0px 4px 4px 0px;
        .seat-row {
          .seat-holder {
            .seat-no {
              top: -1px !important;
              left: -2px !important;
              transform: rotate(270deg) !important;
            }
          }
        }
      }
    }
  }

  .search-table {
    .booking-wrapper {
      margin: 30px 0 !important;
      max-width: 290px !important;
      ul {
        margin: 30px 10px !important;
        li {
          .title {
            min-width: 70px !important;
          }
        }
      }
    }
  }

  .search-table {
    width: 100%;
    .mobileTable {
      .mobileTableContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .mobileTableCard {
          box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.168627451);
          background-color: #ffffff;
          padding: 20px 18px;
          min-width: 300px;
          border-radius: 3px;
          .mobileTableInfoHeadBox {
            .mobileTableHead {
              font-size: 16px;
              color: $text;
              margin-bottom: 10px;
              font-family: "Red Hat Display", sans-serif;
            }
          }
          .mobileTableInfoBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .mobileTableInfoItem {
              display: flex;
              align-items: center;
              color: $input-text;
              .mobileTableInfoText {
                font-size: 14px;
                color: $input-text;
                font-family: "Red Hat Display", sans-serif;

                // color: red !important;
              }
              .mobileTableInfoIcon {
                height: 17px !important;
                width: auto;
                margin-right: 10px;
              }
            }
          }
          .mobileTablePriceBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mobileTablePrice {
              font-size: 14px !important;
              font-weight: bold !important;
              color: #10b981 !important;
              font-family: "Red Hat Display", sans-serif;
            }
            .mobileTablePriceNextBtn {
              background-color: #10b981 !important;
              color: #fff;
              padding: 5px 10px;
              border: none;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .mobileHead {
    display: contents;
  }
  .webHead {
    display: none;
  }

  .mobileTable {
    display: contents;
  }
  .webTable {
    display: none;
  }
  .section-page-wrapper {
    // padding: 100px 0px;
    // background-color: #ffffff;
    .associationImageBox {
      width: 100%;
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .search-table {
    table {
      min-width: 800px;
    }
  }
  .card-wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: white;
    padding: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .association-grid-container {
    display: flex;
    justify-content: center;
  }
  // .payment-icon {
  //   // width: 100%;
  //   // height: 100%;
  //   padding: 2px;
  //   height: 100%;
  //   // width: 100%;
  //   width: 15%;
  //   aspect-ratio: 3/2;
  //   object-fit: contain;
  // }
  .section-wrapper {
    margin-top: 22rem;
    padding: 10px 10px;
    // background-color: #f5f5f5;

    .section-grid {
      display: flex;
      // grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
      // margin: 0 auto;
      // padding: 40px 20px;
      margin-bottom: 10px;
      // background-color: red;
      flex-direction: column;
      justify-content: center;
    }
  }
  .destination-wrapper {
    .popular-wrapper {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-gap: 20px;
      // padding-top: 20px;
      // padding-right: 40px;
    }
    .wrapper-sticker {
      max-width: 150px;
      max-height: 100px;
      // position: absolute;
      display: none;
      bottom: 0;
      right: 0;
      background-color: $primary-dark;
      text-align: center;
      padding: 14px;

      p {
        color: $primary-text;
        font-size: 12px;
        font-weight: 500;
      }

      img {
        height: 25px;
        margin-top: -10px;
      }
    }
    .destination-image {
      display: none;
    }
    .destination-title {
      padding-top: 0px;
      margin-bottom: 0px;
    }
  }
  .footer-wrapper {
    width: 100%;
    color: $primary-text;
    background-color: $dark-background;
    padding: 20px 0px;
    .copyright-container {
      display: flex;
      justify-content: center;
    }

    .footer-logo {
      height: 30px;
      width: auto;
    }

    .footer-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 40px 15px;
      .footerG {
        margin-bottom: 1rem;
      }
      .footerG2 {
        margin-bottom: 1rem;
      }
      .payment-icon {
        // width: 100%;
        // height: 100%;
        padding: 2px;
        height: 100%;
        // width: 100%;
        width: 80%;
        aspect-ratio: 3/2;
        object-fit: contain;
      }
    }
  }
}

// search page
.search-banner {
  background-color: $background;
  height: 100px;
  position: relative;
  .search-banner-search {
    position: absolute;
    z-index: 99;
    bottom: -45px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1140px;
  }
}

.search-page {
  margin-top: 90px;
  .no-ticket-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px;
    .no-tickets-headText {
      text-transform: uppercase;
      color: #454545;
      font-size: 16px;
    }
    .no-tickets-subText {
      text-transform: uppercase;
      color: #8e90a6;
      font-size: 13px;
    }
  }
  .page-title {
    font-size: 20px;
    font-weight: 600;
    display: f;
    // text-align: center;
  }
  .subtitle {
    color: gray;
    font-size: 14px;
    span {
      color: $text;
    }
  }
  .page-date {
    color: gray;
    font-size: 14px;
    span {
      color: $primary;
    }
  }
  .sort-by {
    box-shadow: 0px 5px 12px 0px #8080802b;
    padding: 8px 20px;
    border-radius: 3px;
    font-weight: 300;
    height: 40px;
    margin-left: 15px;
    font-size: 14px;
    select {
      border: none;
      box-shadow: none;
    }
  }
}

// search table
.search-table {
  margin-top: 30px;
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  thead {
    th {
      font-size: 14px;
      font-weight: 600;
      border: none;
    }
    tr {
      border: none;
    }
  }
  tbody {
    tr {
      border: none;
    }
    td {
      font-size: 14px;
    }
    td {
      border-top: 1px solid #d1d1d1;
      border-bottom: none;
    }
    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid #d1d1d1;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #d1d1d1;
    }
    .tr-curved {
      cursor: pointer;
      line-height: 46px;
      &:last-child td {
        border-bottom: 1px solid #d1d1d1;
      }
    }
  }
  // booking-wrapper
  .booking-wrapper {
    background-color: $background;
    border-radius: 4px;
    overflow: hidden;
    margin: 30px auto;
    max-width: 400px;
    .destination {
      background-color: $primary;
      color: $primary-text;
      padding: 15px 30px;
      font-size: 16px;
    }
    ul {
      margin: 30px 30px;
      padding: 0;
      font-size: 16px;

      li {
        display: flex;
        justify-content: flex-start;
        line-height: 36px;
        .title {
          min-width: 100px;
          color: gray;
        }
        .value {
          font-weight: 600;
        }
      }
    }
    button {
      background-color: $primary-light;
      color: $primary-text;
      margin: 0px 30px 30px;
      border: 0;
      border-radius: 4px;
      padding: 10px 30px;
      width: calc(100% - 60px);
      font-size: 16px;
      transition: all 0.1s ease-in-out;
      &:hover {
        background-color: $primary;
      }
    }
  }
  // bus seat wrapper
  .bus-seat-section {
    padding: 15px;
    width: 100%;
    .bus-seat-wrapper {
      border-radius: 4px;
      border: 1px solid #d6d6d6;
      display: flex;
      justify-content: space-between;
      min-width: 500px;

      .driver {
        width: 80px;
        margin-right: 30px;
        align-items: center;
        height: 60px;
        margin-left: 10px;
        text-align: center;
        margin-top: 5px;
        display: flex;
        justify-content: flex-start;
        img {
          height: 36px;
          width: 36px;
          object-fit: contain;
          overflow: hidden;
          &:nth-child(2) {
            height: 46px;
            width: 46px;
            object-fit: contain;
            margin-left: 5px;
          }
        }
      }
      .bus-seat-area {
        padding: 10px 10px 10px 0;
        img {
          height: 36px;
          width: 36px;
        }
        .seat-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .seat-holder {
            cursor: pointer;
            position: relative;
            transition: all 0.2s ease-in-out;
            &:hover {
              transform: scale(1.2);
            }
            .seat-no {
              position: absolute;
              top: 10px;
              left: 4px;
              right: 0;
              color: white;
              font-size: 10px;
            }
          }
        }
      }
    }
    .bus-seat-index {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      li {
        text-align: center;
        .title {
          font-size: 13px;
        }
        img {
          height: 36px;
          width: 36px;
          object-fit: contain;
        }
      }
    }
  }
}

.login-modal.signup-modal {
  width: 530px !important;
  min-height: 605px !important;
  background-color: white;
}

// login modal
.login-modal {
  width: 380px !important;
  // width: 525px !important;style
  // min-height: 605px !important;
  // background-color: white;
  .input-container {
    display: flex;
    gap: 10px;
  }
  .modal-content {
    border-radius: 3px !important;
  }
}
.login-section {
  padding: 30px;
  .main-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .img-wrapper {
    text-align: center;
    img {
      height: 40px;
      width: 40px;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
  }
  input {
    background-color: #f3f3f3;
    border-radius: 4px;
    border: 1px solid #dedcdc;
    padding: 5px;
  }
  button {
    background-color: $primary-light;
    color: $primary-text;
    border: none;
    padding: 8px 30px;
    border-radius: 4px;
    width: -webkit-stretch;
    margin-top: 22px;
    &:hover {
      background-color: $primary;
    }
  }
  .last-line {
    font-size: 13px;
    color: gray;
    text-align: center;
    font-weight: 300;
    margin-top: 10px;
    span {
      color: blue;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: lightgray;
    margin-block: 20px;
    div {
      height: 1px;
      width: 100%;
      background: lightgray;
    }
    p {
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .forgot-password {
    display: inline-block;
    cursor: pointer;
  }
}

// logout
.logout-modal {
  padding: 30px;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    button {
      padding: 6px 20px;
      border: none;
      border-radius: 4px;
    }
    .yes {
      background-color: $primary-light;
      color: $primary-text;
      &:hover {
        background-color: $primary;
      }
    }
    .no {
      background-color: $error;
      color: $primary-text;
      &:hover {
        background-color: $danger;
      }
    }
  }
}

.google-button {
  background-color: transparent !important;
  border: 1px solid #dadce0 !important;
  color: #3c4043 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  img {
    width: 18px;
    height: 18px;
  }
}

// about-us

// .aboutSectionGrid2{

.aboutCompContainer {
  height: auto;
  display: flex;
  align-items: center;
  margin: 40px 0;
  .aboutCompTypography {
    .aboutCompTitle {
      color: $primary;
      font-size: 13px !important;
      font-weight: 700 !important;
      text-transform: uppercase;
      font-family: "Red Hat Display", sans-serif;
      margin-bottom: 10px;
    }
    .aboutCompHead {
      width: 70%;
      color: $text;
      margin-bottom: 10px;
      font-size: 36px !important;
      font-weight: 700 !important;
      font-family: "Red Hat Display", sans-serif;
      text-transform: uppercase;
    }
    .aboutCompPara {
      color: #4c4b63;
      font-size: 14px !important;
      font-weight: 400;
      font-family: "Red Hat Display", sans-serif;
    }
  }

  .aboutSectionGrid2 {
    .imageGrid {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
      .imageBox {
        width: 404px;
        height: 490px;
        margin-bottom: 10px;
        .gridImg {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 16px;
        }
      }
    }
  }
}

// contact-us

.contactFormContainer {
  display: flex;
  justify-content: center;
  padding: 70px 0;
  .contactFormPaperContainer {
    width: 550px;
    .contactFormWrap {
      box-shadow: 0px 8px 20px rgba(18, 17, 39, 0.1);
      padding: 60px;
      .formHeading {
        margin-bottom: 30px;
        .formHead {
          font-size: 30px !important;
          font-weight: 700 !important;
          font-family: "Red Hat Display", sans-serif;
        }
        .formTitle {
          font-size: 14px !important;
          font-weight: 400 !important;
          font-family: "Red Hat Display", sans-serif;
          color: #4c4b63;
        }
      }
      .formSection {
        .formSubmitBtn {
          background-color: $primary;
          color: #fff;
          width: 100%;
          margin-top: 30px !important;
          border-radius: 0 !important;
          text-transform: uppercase !important;
          font-size: 18px !important;
          font-weight: 800 !important;
          font-family: "Red Hat Display", sans-serif;
        }
      }
    }
  }
}

.section-page-wrapper {
  padding: 100px 0px;
  background-color: #ffffff;
  .associationImageBox {
    width: 100%;
    height: 300px;
    .associationImage {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .busInfoContainer {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    .busInfoMainText {
      margin-bottom: 30px;
      color: #383838;
      font-size: 18px;
      font-weight: 600;
      font-family: "Red Hat Display", sans-serif;
      line-height: 150%;
    }
    .busInfoListBox {
      display: flex;
      margin-bottom: 10px;
      // align-items: center;
      .busInfoList {
        margin-left: 10px;
        color: #383838;
        font-size: 14px;
        font-weight: 600;
        font-family: "Red Hat Display", sans-serif;
      }
    }
  }
}
