$primary: #059669;
$primary-dark: #047857;
$primary-light: #10B981;

$primary-green-text : #064E3B;
$secondary-green-text:#065F46;
$primary-text: #fff;

$text: #383751;
$background: #E5E5EB;
$input-background : #F3F3F6;
$input-text : #6C6B80;
$table-row-color : #F9F9FB;

$dark-background: #121127;

$error: #ff3b3b;
$danger: red;
$warning: #ffcc00;
$info: #444bd3;

@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";

$custom-colors: (
	"primary-dark": $primary-dark,
	"primary-light": $primary-light,
	"primary-text": $primary-text,

	"text": $text,
	"background": $background,

	"error": $error,
	"warning": $warning,
	"info": $info,
);

$theme-colors: map-merge($custom-colors, $theme-colors);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
