.my-masonry-grid {
  display: -webkit-box !important; /* Not needed if autoprefixing */
  display: -ms-flexbox !important; /* Not needed if autoprefixing */
  display: flex !important;
  margin-left: -30px !important; /* gutter size offset */
  width: auto !important;
}
.my-masonry-grid_column {
  padding-left: 30px !important; /* gutter size */
  background-clip: padding-box !important;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px !important;
}
